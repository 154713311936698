//
// Nav header
//

.nav {
  > .nav-header {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 5px;
    font-size: 85%;
    font-weight: normal;
    letter-spacing: 1px;
    color: @nav-header-color; // lighten(@gray, 35%);
    text-transform: uppercase;
  }

  > li + .nav-header {
    margin-top: 20px;
  }
}
